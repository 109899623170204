import React, { FC, useEffect, useState } from "react";

import { LoadingBar } from "@amzn/awsui-chat-components";
import { Container, SpaceBetween, Button, ExpandableSection, Box } from "@cloudscape-design/components";
import ReactMarkdown from "react-markdown";

import { ChatMessageProps } from "./ChatMessage";
import MarkdownCodeBlockHighlighter from "../../../rendering-helpers/MarkdownCodeBlockHighlighter";
import { submitFeedbacks } from "../../../utils/ApiHelper";
import { formatIsoTimestampToLocal } from "../../../utils/ChatMessageUtils";
import finneyLogo from "../../image/FinneyLogo.png";
import FeedbackForm from "../feedback/FeedbackForm";

interface AIMessageProps {
  props: ChatMessageProps;
}

/**
 * Returns the message from Finney
 * @param props
 */
export const AIMessage: FC<AIMessageProps> = ({ props }) => {
  const [isSpeaking, setIsSpeaking] = useState<boolean>(props.autoSpeak);
  const [ansCopied, setAnsCopied] = useState<boolean>(false);

  const [showReportFeedbacks, setShowReportFeedbacks] = useState<boolean>(props.showReportFeedbacks ?? false);
  const [rating, setRating] = useState<string>(props.rating ?? "");
  const [feedbacks, setFeedbacks] = useState<string>(props.feedbacks ?? "");

  // positiveFeedbackSubmitted is used to display a message saying that the feedback is submitted and that stays only for 10sec and state is set to false again
  const [positiveFeedbackSubmitted, setPositiveFeedbackSubmitted] = useState<boolean>(false);
  const [thumbsUp, setThumbsUp] = useState<boolean>(props.thumbsUp ?? false);
  const [thumbsDown, setThumbsDown] = useState<boolean>(props.thumbsDown ?? false);
  const [negativeFeedbackSubmitted, setNegativeFeedbackSubmitted] = useState<boolean>(false);

  useEffect(() => {
    setRating(props.rating ?? "");
    setFeedbacks(props.feedbacks ?? "");
  }, [props]);

  /**
   * Function to handle positive Feedback. Calls the metadataFeedback handler to save the feedback
   */
  async function handlePositiveFeedback() {
    await submitFeedbacks("submit-feedbacks", props.messageId, "WEB:GOOD", undefined);
    setThumbsDown(false);
    setThumbsUp(true);
    setPositiveFeedbackSubmitted(true);
    setTimeout(() => setPositiveFeedbackSubmitted(false), 10000);
  }

  /**
   * Function to handle negative Feedback. Calls the metadataFeedback handler to save the feedback
   */
  async function handleNegativeFeedback() {
    // submit feedbacks/rating to endpoint
    // "WEB:GOOD", "WEB:RR1", "WEB:RA1", "WEB:RA2", "WEB:RAH"
    await submitFeedbacks("submit-feedbacks", props.messageId, rating, feedbacks);
    // hide the feedbacks box
    setShowReportFeedbacks(false);
    setThumbsUp(false);
    setThumbsDown(true);
    setNegativeFeedbackSubmitted(true);
    setTimeout(() => setNegativeFeedbackSubmitted(false), 10000);
    setFeedbacks("");
    setRating("");
  }

  /**
   * Function to handle the auto speak functionality
   */
  async function handleAutoSpeak() {
    if (!isSpeaking) {
      setIsSpeaking(true);
      let msgToRead = props.message;
      if (props.message.includes("=== Answer: ")) {
        msgToRead = props.message.split("=== Answer: ")[1];
      }
      const utterance = new SpeechSynthesisUtterance(msgToRead);
      speechSynthesis.speak(utterance);
    } else {
      speechSynthesis.cancel();
      setIsSpeaking(false);
    }
  }
  return (
    <Container data-testid="ai-message">
      <SpaceBetween alignItems="start" direction="horizontal" size="xs">
        {/* AI avatar */}
        <span>
          <img width={32} src={finneyLogo} alt={props.owner.name} />
          {props.isLoading && (
            <div aria-live="polite">
              <Box margin={{ bottom: "xs", left: "l" }} color="text-body-secondary">
                Generating a response...
              </Box>
              <LoadingBar variant="gen-ai" />
            </div>
          )}
        </span>
        <SpaceBetween direction="vertical" size="m">
          {/* TODO hardcoded maxWidth=1100 to make it looks nice -- need to refactor */}
          <div style={{ maxWidth: 1100 }}>
            <ReactMarkdown children={props.message} components={{ code: MarkdownCodeBlockHighlighter }} />
          </div>
          {/* Referenced Sources Expandable */}
          {props.referencedSources && props.referencedSources.length > 0 && (
            <ExpandableSection headerText={"Referenced Sources"} defaultExpanded={true}>
              <div style={{ maxWidth: 1000, overflow: "hidden" }}>
                {props.skill !== "S&A WebMEC Finney" && (
                  <ReactMarkdown
                    children={props.referencedSources.map((c) => `${c}`).join("\n")}
                    components={{ code: MarkdownCodeBlockHighlighter }}
                  />
                )}
                {props.staticReferenceSources
                  ? props.staticReferenceSources.map((c: string) => {
                      return (
                        <div>
                          <a href={c} target="_blank" rel="noopener noreferrer">
                            {c}
                          </a>
                          <br />
                        </div>
                      );
                    })
                  : null}
              </div>
            </ExpandableSection>
          )}
          {/* Citations Expandable */}
          {props.citations && props.citations.length > 0 && (
            <ExpandableSection headerText={"Citations"} defaultExpanded={true}>
              <div style={{ maxWidth: 1000, overflow: "hidden" }}>
                <ReactMarkdown
                  children={props.citations.map((c) => `${c}`).join("\n")}
                  components={{ code: MarkdownCodeBlockHighlighter }}
                />
              </div>
            </ExpandableSection>
          )}
          <div>
            {/* Icon thumbs-up */}
            <Button
              data-testid="thumbs-up-button"
              variant="inline-icon"
              iconName={thumbsUp ? "thumbs-up-filled" : "thumbs-up"}
              onClick={handlePositiveFeedback}
            />
            {positiveFeedbackSubmitted && <span style={{ marginLeft: "10px", color: "green" }}>Submitted!</span>}
            {/* Icon thumbs-down */}
            <Button
              data-testid="thumbs-down-button"
              variant="inline-icon"
              iconName={thumbsDown ? "thumbs-down-filled" : "thumbs-down"}
              onClick={() => setShowReportFeedbacks(!showReportFeedbacks)}
            />
            {negativeFeedbackSubmitted && <span style={{ marginLeft: "10px", color: "green" }}>Submitted!</span>}
            <Button
              data-testid="ai-copy-button"
              variant="inline-icon"
              iconName="copy"
              onClick={() => {
                navigator.clipboard.writeText(props.message);
                setAnsCopied(true);
                setTimeout(() => setAnsCopied(false), 10000);
              }}
            />
            {ansCopied && <span style={{ marginLeft: "10px", color: "green" }}>Copied!</span>}
            <Button
              variant="inline-icon"
              iconName={isSpeaking ? "audio-off" : "audio-full"}
              onClick={handleAutoSpeak}
            />
            <span>On: {formatIsoTimestampToLocal(props.timestamp)}</span>
          </div>
          {/* Feedbacks box - hidden - only show when user clicks the feedback button */}
          <FeedbackForm
            showReportFeedbacks={showReportFeedbacks}
            chatMessage={props}
            rating={rating}
            feedbacks={feedbacks}
            onRatingChange={setRating}
            onFeedbackChange={setFeedbacks}
            onSubmit={handleNegativeFeedback}
          />
        </SpaceBetween>
      </SpaceBetween>
    </Container>
  );
};

export default AIMessage;
