import React from "react";

import { RouteObject } from "react-router-dom";

import Home from "./pages/Home";

/**
 * Enum for page names
 */
export enum PageName {
  Home = "Home",
  Skill = "Skill",
}

/**
 * App routes
 */
export const routes: RouteObject[] = [
  {
    path: "/",
    element: <Home page={PageName.Home} />,
  },
  {
    path: "/skills/:skillId",
    element: <Home page={PageName.Skill} />,
  },
];
