import React from "react";

import { TopNavigation as PolarisTopNavigation, TopNavigationProps } from "@cloudscape-design/components";

import FinergyFloatingMenu from "./FinergyFloatingMenu";
import Logo from "../../image/FinergyLogo.svg";

export interface TopNavProps {
  userAlias?: string;
  skillContactLink?: string;
  aboutLink?: string;
  issueLink?: string;
  skillLabel?: string;
}
export const topNavigationI18nStrings = {
  searchIconAriaLabel: "Search",
  searchDismissIconAriaLabel: "Close search",
  overflowMenuTriggerText: "More",
  overflowMenuTitleText: "All",
  overflowMenuBackIconAriaLabel: "Back",
  overflowMenuDismissIconAriaLabel: "Close menu",
};

/**
 * @param userAlias
 * @returns User Profile Dropdown in the top nav
 */
function userProfileDropdown(userAlias?: string) {
  return {
    type: "menu-dropdown",
    text: userAlias,
    description: userAlias + "@amazon.com",
    iconName: "user-profile",
    iconUrl: `https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${userAlias}`,
    items: [],
  } as TopNavigationProps.Utility;
}

/**
 * @param issueLink
 * @returns Contact Us button in the Top Nav
 */
function reportIssue(issueLink?: string) {
  return {
    type: "button",
    text: "Report an Issue",
    iconName: "ticket",
    href: issueLink,
    items: [],
    external: true,
    externalIconAriaLabel: " (opens in new tab)",
  } as TopNavigationProps.Utility;
}

/**
 * @param skillLink
 * @param skillLabel
 * @returns Contact Skill Owner button in the Top Nav
 */
function contactSkillOwner(skillContactLink?: string, skillLabel?: string) {
  return {
    type: "button",
    text: `Contact ${skillLabel} Owner`,
    iconName: "contact",
    href: skillContactLink,
    items: [],
    external: true,
    externalIconAriaLabel: " (opens in new tab)",
  } as TopNavigationProps.Utility;
}

/**
 * @param aboutLink
 * @returns About Finney button in the Top Nav
 */
function aboutFinney(aboutLink?: string) {
  return {
    type: "button",
    text: "About Finney",
    iconName: "status-info",
    href: aboutLink,
    items: [],
    external: true,
    externalIconAriaLabel: " (opens in new tab)",
  } as TopNavigationProps.Utility;
}

/**
 * @param props
 * @returns the Top Navigation for the UI
 */
export default function TopNav(props: TopNavProps) {
  return (
    <div>
      <FinergyFloatingMenu />
      <PolarisTopNavigation
        data-testid="top-navigation"
        i18nStrings={topNavigationI18nStrings}
        identity={{
          href: "/",
          logo: {
            src: Logo,
          },
        }}
        utilities={[
          reportIssue(props.issueLink),
          contactSkillOwner(props.skillContactLink, props.skillLabel),
          aboutFinney(props.aboutLink),
          userProfileDropdown(props.userAlias),
        ]}
      />
    </div>
  );
}
