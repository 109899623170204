import React from "react";

import { Modal, Box, Button, SpaceBetween } from "@cloudscape-design/components";
import ReactMarkdown from "react-markdown";

import { DISCLAIMER_POP_UP_CONTENT } from "../../common/constants";
import { invokeMetadataEndpoint } from "../../utils/ApiHelper";

interface AcknowledgementProps {
  acknowledged: boolean;
  setAcknowledged: (acknowledged: boolean) => void;
}

export default function Acknowledgement({ acknowledged, setAcknowledged }: AcknowledgementProps) {
  return (
    <Modal
      visible={!acknowledged}
      size="max"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="primary"
              onClick={() => {
                invokeMetadataEndpoint("set-acknowledgement");
                setAcknowledged(true);
              }}
            >
              I Agree
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Finney Usage Guidelines"
    >
      <ReactMarkdown>{DISCLAIMER_POP_UP_CONTENT}</ReactMarkdown>
    </Modal>
  );
}
