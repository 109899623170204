import React, { FC } from "react";

import { Container, Textarea, TextContent } from "@cloudscape-design/components";

import { ChatHistory } from "./ChatHistory";
import { isNotValidSkill } from "../../utils/CommonUtils";

interface ChatContentProps {
  state: any;
  dispatch: any;
  isLoading: boolean;
  messageCount: number;
  smartRoutingDetectedSkillLabel: string;
}

const ChatContent: FC<ChatContentProps> = ({
  state,
  dispatch,
  messageCount,
  isLoading,
  smartRoutingDetectedSkillLabel,
}) => {
  return (
    <Container>
      <div
        id="chat-panel"
        style={{
          position: "relative",
          height: "fit-content",
          padding: 4,
        }}
      >
        <ChatHistory
          introText={state.introText}
          skill={state.chatbotPersona}
          messageHistory={state.messageHistory}
          chatPanelContents={state.chatPanelContents}
          isLoading={isLoading}
        />
      </div>
      <div>
        {isNotValidSkill(smartRoutingDetectedSkillLabel) ? null : (
          <>
            <br></br>
            <small className="green-code">
              <p>
                * Finney detected the skill - <b>{smartRoutingDetectedSkillLabel}</b>. * Finney will continue to route
                all the questions in this conversation to this detected skill. * Click New Conversation to select a new
                skill.
              </p>
            </small>
          </>
        )}
      </div>
      <TextContent>
        <p>
          <small>Messages Count: {messageCount}/50</small>
          {messageCount >= 50 ? (
            <>
              <br />
              <p style={{ color: "red" }}>
                This conversation has reached the maximum number of messages, please start a new conversation.
              </p>
            </>
          ) : (
            <></>
          )}
        </p>
      </TextContent>
      <Textarea
        name="userTask"
        value={state.question}
        rows={4}
        onChange={(e: any) => dispatch({ type: "SET_QUESTION", question: e.detail.value })}
        placeholder={state.userTask}
      ></Textarea>
    </Container>
  );
};

export default ChatContent;
