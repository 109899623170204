import { Auth } from "aws-amplify";

import { invokeConversationEndpoint, invokeMetadataEndpoint } from "./ApiHelper";
import { DEFAULT_SKILL_DROPDOWN_LABEL, DEFAULT_SKILL_DROPDOWN_VALUE, skillsMapping } from "../common/constants";

/**
 * Populates the Intro Text and Example Task based on the skill
 * @param skillId
 * @param setChatbotPersona
 * @param setIntroText
 * @param setUserTask
 */
/* istanbul ignore next */
export function populateIntroTextForSkill(
  skillId: string,
  setChatbotPersona: (arg0: { label: any; value: string }) => void,
  setIntroText: (arg0: any) => void,
  setUserTask: (arg0: any) => void
) {
  const skill = skillsMapping[skillId];
  if (skill) {
    setChatbotPersona({ label: skill.skillName, value: skillId });
    setIntroText(skill.skillDescription);
    setUserTask(skill.exampleTask);
  }
}

/**
 * Retrieves available skills for web when page loads
 */
/* istanbul ignore next */
export async function getSkills(setFinneySkills: any) {
  const skills = await invokeMetadataEndpoint("list-available-skills");
  const finneySkills = transformAvailableSkillsToDropdownObjects(skills);
  setFinneySkills(finneySkills);
}

/* istanbul ignore next */
function transformAvailableSkillsToDropdownObjects(
  data: { skillName: string; skillId: string }[]
): { label: string; value: string }[] {
  let availableSkills = [];
  availableSkills.push({ label: DEFAULT_SKILL_DROPDOWN_LABEL, value: DEFAULT_SKILL_DROPDOWN_VALUE });
  return availableSkills.concat(
    data.map((item) => ({
      label: item.skillName,
      value: item.skillId,
    }))
  );
}

/**
 * Retrieves the status of user disclaimer acknowledgement
 */
/* istanbul ignore next */
export async function getAcknowledgement(setAcknowledged: any) {
  const acknowledgement = (await invokeMetadataEndpoint("get-acknowledgement")).body;
  setAcknowledged(acknowledgement.acknowledged);
}

/**
 * Function to check is the skill has a valid text and not empty.
 */
export const isNotValidSkill = (skill: any) => {
  return skill === null || skill === "-" || skill === undefined || skill === "";
};

/**
 * Retrieves the conversation history of the user
 */
/* istanbul ignore next */
export async function getConversationHistory(startDate: string, endDate: string, pageIndex: string, pageSize: string) {
  const conversationsResponse = await invokeConversationEndpoint(
    "get-conversations",
    pageIndex,
    pageSize,
    "", // This represents empty conversationId for get-conversations action
    startDate,
    endDate
  );
  return conversationsResponse;
}

/**
 * Retrieves the messages from a conversation
 */
/* istanbul ignore next */
export async function getMessagesByConversationId(conversationId: string, pageIndex: string, pageSize: string) {
  const messagesResponse = await invokeConversationEndpoint("get-messages", pageIndex, pageSize, conversationId);
  return messagesResponse;
}

/**
 * Checks the current authentication session and refreshes the token if it's close to expiration.
 *
 * This function performs the following steps:
 * 1. Retrieves the current authentication session.
 * 2. Checks if the access token is about to expire (within the next 5 minutes).
 * 3. If the token is near expiration, it refreshes the user's authentication.
 * 4. If any error occurs (e.g., no current session or refresh fails), it attempts to initiate a new federated sign-in.
 *
 * @async
 * @function refreshTokenIfNeeded
 * @throws {Error} If there's an issue retrieving the current session or refreshing the token.
 */
export async function refreshTokenIfNeeded() {
  try {
    const session = await Auth.currentSession();
    const expiration = session.getAccessToken().getExpiration();
    const now = Math.floor(Date.now() / 1000);

    // If token is about to expire in the next 5 minutes, refresh it
    if (expiration - now < 300) {
      await Auth.currentAuthenticatedUser({ bypassCache: true });
    }
  } catch (error) {
    console.error("Error refreshing token:", error);
    // Handle the error, possibly by redirecting to login
    await Auth.federatedSignIn({ customProvider: "AmazonFederate" });
  }
}

export const checkUserAuthentication = async () => {
  try {
    await Auth.currentAuthenticatedUser();
  } catch (error) {
    // If the user is not authenticated, sign them back in
    try {
      await Auth.federatedSignIn({ customProvider: "AmazonFederate" });
    } catch (signInError) {
      console.error("Failed to sign in:", signInError);
    }
  }
};
