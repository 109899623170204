import React, { FC } from "react";

import { Button, ButtonDropdown, Select, SpaceBetween } from "@cloudscape-design/components";

import { isNotValidSkill } from "../../utils/CommonUtils";

export interface HomeActionsProps {
  state: any;
  dispatch: any;
  handleNewConversation: () => void;
  processUserTask: () => void;
  checkButtonDisable: (skillId: string) => boolean;
  smartRoutingDetectedSkillLabel: any;
  navigate: any;
  finneySkills: any[];
  autoSpeak: boolean;
  businessTenants: any[];
  businessTenantSelected: string;
  docTypes: any[];
  docTypeSelected: string;
  docSelected: string;
  docs: any[];
}

export const HomeActions: FC<HomeActionsProps> = ({
  state,
  dispatch,
  processUserTask,
  handleNewConversation,
  checkButtonDisable,
  smartRoutingDetectedSkillLabel,
  navigate,
  finneySkills,
  autoSpeak,
  businessTenants,
  businessTenantSelected,
  docTypes,
  docTypeSelected,
  docSelected,
  docs,
}) => {
  return (
    <div>
      <SpaceBetween size={"s"} direction="horizontal">
        <Button
          data-testid="home-new-conversation-button"
          variant="primary"
          disabled={false}
          onClick={handleNewConversation}
        >
          New Conversation
        </Button>
        <Select
          onChange={async ({ detail }) => {
            dispatch({
              type: "SET_CHATBOT_PERSONA",
              chatbotPersona: detail.selectedOption as any,
            });
            navigate(`/skills/${detail.selectedOption.value}?autoSpeak=${autoSpeak}`);
          }}
          selectedOption={state.chatbotPersona}
          options={finneySkills}
          disabled={!isNotValidSkill(state.smartRoutingDetectedSkillValue) || state.isLoading || state.isSkillLocked}
        />
        {(smartRoutingDetectedSkillLabel == "FinQ&A" || state.chatbotPersona.value === "fin-qna") &&
        businessTenants.length > 0 ? (
          <ButtonDropdown
            items={state.businessTenants}
            onItemClick={(e) => {
              dispatch({
                type: "SET_BUSINESS_TENANT_SELECTED",
                businessTenantSelected: e?.detail?.id === "none" ? "ALL" : e?.detail?.id,
              });

              const selectedTenantObj = state.finqnaMetadata.filter((tenant: { key: string }) => {
                return tenant.key === e?.detail?.id;
              });
              const docTypes = selectedTenantObj[0].value.map((docType: any) => {
                return {
                  id: docType.key,
                  text: docType.key,
                };
              });
              dispatch({ type: "SET_DOC_TYPES", docTypes: docTypes });
              dispatch({ type: "SET_DOC_TYPE_SELECTED", docTypeSelected: "Please Select" });
              dispatch({ type: "SET_DOCS", docs: [] });
              dispatch({ type: "SET_DOC_SELECTED", docSelected: "Please Select" });
            }}
          >
            Team: {businessTenantSelected}
          </ButtonDropdown>
        ) : null}
        {(smartRoutingDetectedSkillLabel == "FinQ&A" || state.chatbotPersona.value === "fin-qna") &&
        docTypes.length > 0 ? (
          <ButtonDropdown
            items={docTypes}
            onItemClick={(e) => {
              dispatch({
                type: "SET_DOC_TYPE_SELECTED",
                docTypeSelected: e?.detail?.id === "none" ? "ALL" : e?.detail?.id,
              });

              const selectedTenantObj = state.finqnaMetadata.filter((tenant: { key: string }) => {
                return tenant.key === businessTenantSelected;
              });
              const selectedDocTypeObj = selectedTenantObj[0].value.filter((docType: any) => {
                return docType.key === e?.detail?.id;
              });
              const _docs = selectedDocTypeObj[0].value.map((doc: any) => {
                return {
                  id: doc,
                  text: doc,
                };
              });
              dispatch({ type: "SET_DOCS", docs: _docs });
              dispatch({
                type: "SET_DOC_SELECTED",
                docSelected: `All ${e?.detail?.id} Documents`,
              });
            }}
          >
            Doc Type: {docTypeSelected}
          </ButtonDropdown>
        ) : null}
        {(smartRoutingDetectedSkillLabel == "FinQ&A" || state.chatbotPersona.value === "fin-qna") && docs.length > 0 ? (
          <ButtonDropdown
            items={state.docs}
            onItemClick={(e) => {
              dispatch({
                type: "SET_DOC_SELECTED",
                docSelected: e?.detail?.id === "none" ? "ALL" : e?.detail?.id,
              });
            }}
          >
            Doc: {docSelected}
          </ButtonDropdown>
        ) : null}
        <Button variant="primary" onClick={processUserTask} disabled={checkButtonDisable(state.chatbotPersona.value)}>
          Finney Go!
        </Button>
      </SpaceBetween>
    </div>
  );
};
