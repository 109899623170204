import React, { FC, useState } from "react";

import { Container, SpaceBetween, Button } from "@cloudscape-design/components"; // Adjust this import based on your UI library
import ReactMarkdown from "react-markdown";

import { ChatMessageProps } from "./ChatMessage";
import MarkdownCodeBlockHighlighter from "../../../rendering-helpers/MarkdownCodeBlockHighlighter";
import { formatIsoTimestampToLocal } from "../../../utils/ChatMessageUtils"; // Adjust this import based on your MarkdownCodeBlockHighlighter
interface UserMessageProps {
  props: ChatMessageProps;
}

/**
 * Returns the message from the User
 * @param chatMessageProps
 */
export const UserMessage: FC<UserMessageProps> = ({ props }) => {
  const [questionCopied, setQuestionCopied] = useState<boolean>(false);

  if (props.owner.isAI) return null;
  return (
    <Container data-testid="user-message">
      <SpaceBetween direction="horizontal" size="m">
        {/* User avatar */}
        <span>
          <img width={32} src={props.owner.avatarUrl} alt={props.owner.name} />
        </span>
        <SpaceBetween direction="vertical" size="xs">
          <div style={{ maxWidth: 1100 }}>
            <ReactMarkdown children={props.message} components={{ code: MarkdownCodeBlockHighlighter }} />
          </div>
          <div>
            <Button
              data-testid="user-copy-button"
              variant="inline-icon"
              iconName="copy"
              onClick={() => {
                navigator.clipboard.writeText(props.message);
                setQuestionCopied(true);
                setTimeout(() => setQuestionCopied(false), 10000);
              }}
            />
            {questionCopied && <span style={{ marginLeft: "10px", color: "green" }}>Copied!</span>}
            <span>On: {formatIsoTimestampToLocal(props.timestamp)}</span>
          </div>
        </SpaceBetween>
      </SpaceBetween>
    </Container>
  );
};
export default UserMessage;
