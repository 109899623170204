import { CognitoUser } from "@aws-amplify/auth";

interface CognitoUserAmplify extends CognitoUser {
  username?: string;
}

/**
 * Retrieve User Alias and Jwt Token for the logged-in user
 * @param user
 */
export const getUserAttribute = (user: CognitoUserAmplify) => {
  if (!user) {
    return {
      alias: "",
      jwtToken: "",
    };
  }
  const payload = user.getSignInUserSession()?.getIdToken().payload;
  const jwtToken = user.getSignInUserSession()?.getIdToken()?.getJwtToken() ?? "";
  const userAlias = payload?.["cognito:username"].split("_")[1];
  return {
    alias: userAlias,
    jwtToken: jwtToken,
  };
};
