import React from "react";

import { Box } from "@amzn/awsui-components-react-v3";

import "./style.scss";

export interface HeaderProps {
  category?: string;
  title?: string;
  subtitle?: string;
  description?: string;
}

/**
 * Returns the banner for each page
 * @param props
 */
export function Header(props: HeaderProps) {
  return (
    <div className="header" data-testid="hero-header">
      <Box>
        <Box fontSize="heading-s" fontWeight="heavy" padding={{ top: "l", left: "l" }}>
          <span className="category">{props.category}</span>
        </Box>
        <div className="title">
          <Box variant="h1" fontWeight="heavy" padding={{ left: "m" }} fontSize="display-l" color="inherit">
            {props.title}
          </Box>
          <Box fontWeight="normal" padding={{ bottom: "s", left: "m" }} fontSize="heading-m" color="inherit">
            {props.subtitle}
          </Box>
          <Box fontSize="heading-s" fontWeight="heavy" padding={{ bottom: "xl", left: "m" }}>
            <span className="description">{props.description}</span>
          </Box>
        </div>
      </Box>
    </div>
  );
}
