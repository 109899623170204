import React, { ReactNode, useEffect, useState } from "react";

import { Authenticator } from "@aws-amplify/ui-react";
import Spinner from "@cloudscape-design/components/spinner";
import { Amplify, Auth } from "aws-amplify";

import { envConfig } from "../amplify/AmplifyConstants";

/**
 * Props for the AuthProvider component.
 */
interface AuthProviderProps {
  children: ReactNode;
}

/**
 * AuthProvider component handles authentication and configuration for the application.
 * It uses AWS Amplify for authentication and manages the authentication state.
 *
 * @param {AuthProviderProps} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const [isConfiguring, setIsConfiguring] = useState(true);

  useEffect(() => {
    /**
     * Configures Amplify and checks the current authentication state.
     * If not authenticated, it initiates federated sign-in.
     */
    const configure = async () => {
      try {
        Amplify.configure(envConfig);
        await Auth.currentAuthenticatedUser();
        setIsConfiguring(false);
      } catch (error) {
        console.error(error);
        // Store the current URL before redirecting
        sessionStorage.setItem("redirectUrl", window.location.pathname + window.location.search);
        Auth.federatedSignIn({
          customProvider: "AmazonFederate",
          // Pass the redirect URL as a custom state if the IdP supports it
          customState: window.location.pathname + window.location.search,
        });
      }
    };
    configure();
  }, []);

  useEffect(() => {
    /**
     * Handles redirection after successful authentication.
     */
    if (!isConfiguring) {
      // Check for stored redirect URL after successful authentication
      const redirectUrl = sessionStorage.getItem("redirectUrl");
      if (redirectUrl) {
        sessionStorage.removeItem("redirectUrl");
        window.location.href = redirectUrl;
        // TODO: Current usage of window.location.replace() removes the URL from browser history,
        //  preventing back/forward navigation. Implement a more robust solution instead of replace
        window.location.replace(redirectUrl);
      }
    }
  }, [isConfiguring]);

  if (isConfiguring) {
    return <Spinner />;
  }

  return (
    <React.Suspense fallback={<Spinner />}>
      <Authenticator.Provider>{children}</Authenticator.Provider>
    </React.Suspense>
  );
};
