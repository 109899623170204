/**
 * Converts the isoTimestamp to local
 * @param isoTimestamp
 */
export function formatIsoTimestampToLocal(isoTimestamp: string) {
  const date = new Date(isoTimestamp);
  return date.toLocaleString();
}

/**
 * Dropdown rating options for negative feedback
 */
export const feedbackRatingOptions = [
  {
    text: "Answer is not factually correct",
    id: "Answer is not factually correct",
    disabled: false,
  },
  {
    text: "Answer does not address question",
    id: "Answer does not address question",
    disabled: false,
  },
  {
    text: "Answer is based on outdated info",
    id: "Answer is based on outdated info",
    disabled: false,
  },
  {
    text: "Incorrect skill detected",
    id: "Incorrect skill detected",
    disabled: false,
  },
  {
    text: "Other - please specify",
    id: "Other - please specify",
    disabled: false,
  },
];
