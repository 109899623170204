// ProcessUserTask.ts

import { Auth } from "aws-amplify";

import { lambdaUrlStreaming } from "./ApiHelper";
import { refreshTokenIfNeeded, isNotValidSkill } from "./CommonUtils";
import { getUserAttribute } from "./user";
import { FIN_QNA_SKILL_ID, skillsMapping } from "../common/constants";
import { ChatMessageProps } from "../components/chatbot/message/ChatMessage";

export const processUserTask = async (state: any, dispatch: any, user: any, userData: any) => {
  try {
    await refreshTokenIfNeeded();
    if (!user || !userData) {
      await Auth.currentAuthenticatedUser();
      userData = getUserAttribute(user);
    }
  } catch (error) {
    console.error("Error refreshing user authentication state:", error);
  }

  if (!state.question) {
    alert("Please enter a question/task. Looks like you did not put in anything for Finney.");
    return;
  }

  dispatch({ type: "SET_IS_LOADING", isLoading: true });
  dispatch({ type: "SET_USER_TASK", userTask: "" });
  dispatch({ type: "SET_QUESTION", question: "" });
  dispatch({ type: "SET_MESSAGE_COUNT", messageCount: state.messageCount + 1 });
  let isSmartRoutedQuestion = false;
  if (state.chatbotPersona.value === "smart-routing") {
    isSmartRoutedQuestion = true;
  } else {
    dispatch({ type: "SET_IS_SKILL_LOCKED", isSkillLocked: true });
  }

  const messageHistory = [...state.messageHistory];
  messageHistory.push({
    autoSpeak: state.autoSpeak,
    messageCount: state.messageCOunt,
    timestamp: new Date().toISOString(),
    message: state.question,
    skill: state.chatbotPersona.label,
    messageId: `dummy-id-${Date.now()}`, // fake it because it does not exist yet
    conversationId: state.conversationId,
    staticReferenceSources: [],
    referencedSources: [],
    citations: [],
    owner: {
      isSelf: true,
      isAI: false,
      avatarUrl: `https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${userData.alias}`,
      name: userData.alias + "@",
      alias: userData.alias,
    },
  } as ChatMessageProps);
  dispatch({ type: "ADD_TO_MESSAGE_HISTORY", message: messageHistory[messageHistory.length - 1] });
  let chatPanelDom = document.getElementById("chat-panel")!;
  chatPanelDom.scrollTop = chatPanelDom.scrollHeight;

  const userInput = state.question;
  const start = Date.now();
  dispatch({
    type: "SET_DEBUG_MESSAGE",
    debugMessage: `${new Date().toLocaleString()} - CLIENT - INFO - Sent message to Finney "${userInput.substring(
      0,
      36
    )}..."`,
  });
  try {
    const { messageId, _conversationId, finalAnswer, referencedSources, smartRoutingDetectedSkill } =
      await lambdaUrlStreaming(
        userInput,
        state.conversationId,
        isNotValidSkill(state.smartRoutingDetectedSkillValue)
          ? state.chatbotPersona.value
          : state.smartRoutingDetectedSkillValue,
        (chatPanelContents) => dispatch({ type: "SET_CHAT_PANEL_CONTENTS", chatPanelContents: chatPanelContents }),
        state.chatPanelContents,
        (debugMessage) => dispatch({ type: "SET_DEBUG_MESSAGE", debugMessage: debugMessage }),
        state.autoSpeak,
        state.businessTenantSelected,
        state.docTypeSelected,
        state.docSelected
      );
    dispatch({ type: "SET_CONVERSATION_ID", conversationId: _conversationId });
    if (isSmartRoutedQuestion && !isNotValidSkill(smartRoutingDetectedSkill)) {
      const smartRoutedSkill = skillsMapping[smartRoutingDetectedSkill];
      dispatch({
        type: "SET_SMART_ROUTING_SKILL_LABEL",
        smartRoutingDetectedSkillLabel: smartRoutedSkill.skillName,
      });
      dispatch({ type: "SET_SMART_ROUTING_SKILL_VALUE", smartRoutingDetectedSkillValue: smartRoutedSkill.skillId });
      if (smartRoutingDetectedSkill === FIN_QNA_SKILL_ID) {
        dispatch({ type: "SET_QUESTION", question: userInput });
      }
    }
    const messageHistory = [...state.messageHistory];
    messageHistory.push({
      messageId: messageId,
      autoSpeak: state.autoSpeak,
      messageCount: state.messageCOunt,
      isLoading: state.isLoading,
      conversationId: state.conversationId,
      message: finalAnswer,
      skill: isNotValidSkill(state.smartRoutingDetectedSkillLabel)
        ? state.chatbotPersona.label
        : state.smartRoutingDetectedSkillLabel,
      timestamp: new Date().toISOString(),
      citations: [],
      staticReferenceSources:
        state.chatbotPersona.value === "sna-web-mec"
          ? ["https://w.amazon.com/bin/view/FOX/Core/Products/MEC/UserGuide/Home"]
          : [],
      referencedSources: referencedSources,
      owner: {
        isSelf: false,
        isAI: true,
        avatarUrl: `https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${userData.alias}`,
        name: userData.alias + "@",
        alias: userData.alias,
      },
    } as ChatMessageProps);
    dispatch({ type: "ADD_TO_MESSAGE_HISTORY", message: messageHistory[messageHistory.length - 1] });
    chatPanelDom.scrollTop = chatPanelDom.scrollHeight;

    const duration = ((Date.now() - start) / 1000).toFixed(2);
    dispatch({
      type: "SET_DEBUG_MESSAGE",
      debugMessage: `${new Date().toLocaleString()} - CLIENT - INFO - Done. Took ${duration} seconds to get answer from LLM\n`,
    });
  } catch (error) {
    console.warn("Failed to parse LLM answer, error was", error);
    const errorMsg = `${new Date().toLocaleString()} - CLIENT - ERROR - Failed to parse LLM answer, error was: ${error}\n`;
    dispatch({ type: "SET_DEBUG_MESSAGE", debugMessage: errorMsg });
    dispatch({ type: "SET_IS_LOADING", isLoading: false });
  }
  dispatch({ type: "SET_IS_LOADING", isLoading: false });
};
