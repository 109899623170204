import React from "react";

import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { routes } from "./routes";
import { AuthProvider } from "./utils/AuthProvider";

/**
 * Asynchronously renders the main application component.
 *
 * This function sets up the necessary providers and routers for the React application.
 *
 * @returns {Promise<void>} A Promise that resolves when the application has been successfully rendered.
 */
async function renderApp() {
  const root = createRoot(document.getElementById("root") as HTMLElement);
  root.render(
    <React.StrictMode>
      <AuthProvider>
        <RouterProvider router={createBrowserRouter(routes)} />
      </AuthProvider>
    </React.StrictMode>
  );
}

renderApp();
