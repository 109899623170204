import React from "react";

import { skillsMapping } from "../../common/constants";
import TopNav from "../navigation/top-navigation/TopNavigation";

export default function AppHeader({
  chatbotPersona,
  page,
  userAlias,
}: {
  chatbotPersona: any;
  page: any;
  userAlias: string;
}) {
  return (
    <div id="b">
      <div id="h" style={{ position: "sticky", top: 0, zIndex: 1002 }}>
        <TopNav
          userAlias={userAlias}
          issueLink={skillsMapping[chatbotPersona.value].issueLink}
          aboutLink={skillsMapping[chatbotPersona.value].aboutLink}
          skillContactLink={skillsMapping[chatbotPersona.value].skillLink}
          skillLabel={skillsMapping[chatbotPersona.value].skillLabel}
        />
      </div>
    </div>
  );
}
