import React from "react";

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/cjs/styles/prism";

/**
 * Helper Rendering class Syntax highlighting the code block.
 * Supported languages: ['python', 'java', 'javascript', 'c++', 'typescript', 'objective-c', 'json']
 */
const MarkdownCodeBlockHighlighter: any = ({
  inline,
  className,
  children,
}: {
  inline: boolean;
  className: any;
  children: any;
}) => {
  const match = /language-(\w+)/.exec(className || "");
  let codeLanguage = "javascript";
  let langs = ["python", "java", "javascript", "c++", "typescript", "objective-c", "json"];

  for (const lang of langs) {
    if (match && match[1] && match[1].startsWith(lang)) {
      codeLanguage = lang;
    }
  }

  // handle situation when there is only 1 line, inline code, do not use SyntaxHighlighter
  // this way it's easier to read
  if (!`${children}`.includes("\n")) {
    return <span className="markdown-inline-code">&nbsp;&nbsp;{children}&nbsp;&nbsp;</span>;
  }

  // happy path, code block with multiple lines, newline is '\n'
  return (
    <SyntaxHighlighter
      language={codeLanguage}
      style={dark}
      useInlineStyles={true}
      showLineNumbers={true}
      showInlineLineNumbers={false}
    >
      {children}
    </SyntaxHighlighter>
  );
};
export default MarkdownCodeBlockHighlighter;
